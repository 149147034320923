import createRequestTypes from "../../helpers/createReduxActionTypes";

// Clients
export const CLIENTS_FETCH = createRequestTypes("CHAT.CLIENTS_CHAT_FETCH");

// Chat
export const MESSAGES_FETCH = createRequestTypes("CHAT.MESSAGES_FETCH");
export const MESSAGES_SHOW = "CHAT.MESSAGES_SHOW";
export const MESSAGES_UPDATE = "CHAT.MESSAGES_UPDATE";
export const CONVERSATIONS_FETCH = createRequestTypes("CONVERSATIONS_FETCH");
export const CONVERSATIONS_SHOW_MODAL = "CONVERSATIONS_SHOW_MODAL";
export const CONVERSATIONS_SEND_MESSAGE_TO_ALL_CLIENTS =
  "CONVERSATIONS_SEND_MESSAGE_TO_ALL_CLIENTS";
export const CONVERSATIONS_ORDER = "CONVERSATIONS_ORDER";
export const CONVERSATION_SELECTED_UPDATE = "CONVERSATION_SELECTED_UPDATE";
export const MOBILE_VIEW = "MOBILE_VIEW";
export const SET_SCROLLED = "SET_SCROLLED";
export const S3_BEFORE_AFTER_IMAGES =
  "https://zenfit-images.s3.eu-central-1.amazonaws.com/before-after-images/client/photo/";
export const DEFAULT_IMAGE_URL = require("../../assets/images/placeholder_user.png");
export const PLAY_BUTTON_URL = require("../../assets/images/play.png");
export const PAUSE_BUTTON_URL = require("../../assets/images/pause.png");
export const WAVE_URL = require("../../assets/images/wave.png");
export const SUNSET_CARD_IMAGE_URL = require("../../assets/images/sunset_card_image.png");
export const MESSAGE_PENDING = "pending";
export const MESSAGE_READ = "read";
// TODO: this action was missing and is still in the reducer? is it used?
export const CONVERSATIONS_MARK_AS_SEEN = "CONVERSATIONS_MARK_AS_SEEN";
